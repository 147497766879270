<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Liste des factures">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'MainVue',
  components: {
    BRow, BCol, BCard, VueGoodTableVue,
  },
  data() {
    return {
      columns: [
        {
          label: 'Référence',
          field: 'reference',
        },
        {
          label: 'Point de vente',
          field: 'pointDeVente',
        },
        {
          label: 'Secteur d\'activité',
          field: 'secteur',
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
        {
          label: 'Montant',
          field: 'montant',
        },
        {
          label: 'Montant payé',
          field: 'montantPaye',
        },
        {
          label: 'Date limite',
          field: 'dateLimite',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
        {
          label: 'Livraison',
          field: 'livraison',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [
        {
          reference: 'F1001',
          pointDeVente: 'Point de vente 1',
          secteur: 'Cosmétiques',
          date: '2023-01-01',
          montant: 1000000,
          montantPaye: 500000,
          dateLimite: '2023-02-01',
          livraison: 'Oui',
          action: 'Voir détails',
        },
        {
          reference: 'F1002',
          pointDeVente: 'Point de vente 2',
          secteur: 'Alimentation',
          date: '2023-01-05',
          montant: 500000,
          montantPaye: 500000,
          dateLimite: '2023-02-05',
          livraison: 'Oui',
          action: 'Voir détails',
        },
        {
          reference: 'F1003',
          pointDeVente: 'Point de vente 3',
          secteur: 'Informatique',
          date: '2023-01-10',
          montant: 1500000,
          montantPaye: 0,
          dateLimite: '2023-02-10',
          livraison: 'Non',
          action: 'Voir détails',
        },
        {
          reference: 'F1004',
          pointDeVente: 'Point de vente 4',
          secteur: 'Mode',
          date: '2023-01-15',
          montant: 750000,
          montantPaye: 750000,
          dateLimite: '2023-02-15',
          livraison: 'Oui',
          action: 'Voir détails',
        },
        {
          reference: 'F1005',
          pointDeVente: 'Point de vente 5',
          secteur: 'Cosmétiques',
          date: '2023-01-20',
          montant: 2000000,
          montantPaye: 1000000,
          dateLimite: '2023-02-20',
          livraison: 'Oui',
          action: 'Voir détails',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
